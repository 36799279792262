import React from "react";
import resumeFile from "../documents/TrevorKBuchanan_Resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2016 - 2019",
      title: "Aviation Management",
      place: "Louisiana Tech University",
      desc: "BS Aviation Management and minor in Business Administration.",
    },
    {
      yearRange: "2021 - Current",
      title: "Software Developer",
      place: "Bossier Parish Community College",
      desc: "Associates degree in Software Development.",
    },
    {
      yearRange: "2022",
      title: "Professional Scrum Master, (PSM 1)",
      place: "Scrum.org",
    },
    {
      yearRange: "2022",
      title: "Tableau Desktop Specialist",
      place: "Tableau",
    }
  ];

  const experienceDetails = [
    {
      yearRange: "January 2023 - Current",
      title: "Database Analyst Associate",
      place: "General Dynamics Information Technology",
      desc: "Currently, I create, refine, and deliver reports and dashboards for internal and external use, building these in ServiceNow and utilizing Performance Analytics for insights. I leverage Tableau, Excel, and other business intelligence tools to provide data analytics and key insights, defining Key Performance Indicators (KPIs) to measure performance across various sections. I collaborate with a team of developers, analysts, and engineers to determine feature requests, priorities, dashboards, and solutions, and participate in Agile development processes, writing user stories, acceptance criteria, and test plans. I perform Quality Assurance (QA) testing for new features, support user acceptance testing, prepare and conduct presentations, and collaborate with my team to ensure timely Agile sprint releases.",
    },
    {
      yearRange: "November 2019 - December 2022",
      title: "Lead Operational Control Specialist/Continuous Quality Improvement Analyst",
      place: "Metro Aviation, Inc.",
      desc: "As Lead OCS, provided direction and control guidelines to a team of 16 FAA-qualified operational control specialists, and used Business Intelligence tools to generate workload reports that influenced staffing decisions. I traveled nationwide for software/hardware training, utilized Python for automated database management, and created databases using Microsoft SQL for Tableau and PowerBI connections. Furthermore, I prepared presentations for upper management, maintained Tableau and PowerBI dashboards, and assisted in developing Standard Operating Procedures for the Operational Control Center.",
    },
    {
      yearRange: "March 2017 - November 2019",
      title: "Head Lineman",
      place: "Ruston Aviation, Inc.",
      desc: "As Head Lineman, I oversaw flight statuses, developed fuel inspection protocols, managed scheduling for aircraft and employees, and ensured the safety and quality of fuel transfers. My duties also encompassed financial administration through invoice and payroll management, HR responsibilities like conflict mediation and candidate evaluation, and conducting safety meetings to uphold stringent industry standards.",
    },
  ];

  const skills = [
    {
      name: "Tableau",
      percent: 90,
    },
    {
      name: "Power BI",
      percent: 25,
    },
    {
      name: "MSSQL",
      percent: 80,
    },
    {
      name: "Excel/Power Query",
      percent: 50,
    },
    {
      name: "Aviation Weather",
      percent: 90,
    },
    {
      name: "Aviation FARs",
      percent: 90,
    }
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education & Certificates
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download Resume
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
